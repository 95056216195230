
export default {
  'main.title': 'Alcohol Test Management',
  'menu.webhook': 'Data Push',
  'menu.drivelog': 'Drive Log',
  'menu.attendance': 'Attendance',
  'home.home': 'Home',
  'user.account': 'Account',
  'user.email': 'Email',
  'user.input.keyboard.error': 'Please set the input method of the keyboard to half-width mode',
  'user.email.tip': 'Please enter your email address',
  'user.password': 'Password',
  'user.reg': 'Register',
  'user.reg.success': 'Registration successful',
  'user.reg.fail': 'Registration failed',
  'user.reg.success.description': 'Redirecting to login page shortly',
  'user.regnow': 'Register Now',
  'user.login': 'Login',
  'user.signout': 'Sign Out',
  'user.captcha': 'Captcha',
  'user.signInGoogle': 'Login with Google',
  'user.emailCaptcha': 'Verification Code',
  'user.emailCaptcha.tip': 'Please enter the verification code sent to your email',
  'user.getCaptcha': 'Get Verification Code',
  'user.readAndAgree': 'I have read and agree to',
  'user.UserServiceAgreement': 'User Service Agreement',
  'user.action.delete': 'Delete',
  'user.action.more': 'More Actions',
  'menu.record': 'Test Records',
  'menu.driver': 'Driver Management',
  'menu.title': 'Alcohol Test Management',
  'menu.daily.data': 'Data Daily Report',
  'user.accountSetting': 'Account Settings',
  'user.tip': 'Tip',
  'user.name.change.title': 'Change Name',
  'user.signOutTip': 'Are you sure you want to sign out?',
  'table.emptyText': 'No data available',
  'user.action': 'Action',
  'user.name': 'Name',
  'user.name.tip': 'Please enter your name',
  'user.bindTime': 'Binding Time',
  'user.action.search': 'Search',
  'user.action.bind': 'Bind Driver',
  'user.action.qrWinOpenTip': 'Do not close this window before the driver completes the QR code scan authorization.',
  'user.action.scanBind': 'Bind Driver via Scanning QR Code',
  'user.action.emailBind': 'Bind Driver via Admin Email Input',
  'user.visit.tip': 'Please invite the driver to scan this QR code in person.',
  'user.action.bind.success': 'Driver binding successful',
  'user.test.id': 'Serial',
  'user.test.value': 'Detection Value (mg/L)',
  'user.test.date': 'Date',
  'user.test.time': 'Time',
  'user.id': 'Driver ID',
  'user.test.appraise': 'Assessment',
  'user.test.user': 'Tester',
  'user.test.image': 'Photo',
  'user.test.appraise.yes': 'Yes',
  'user.test.appraise.no': 'No',
  'user.car.license_plate_number': 'License Plate Number',
  'user.action.download': 'Download',
  'user.action.download.tip': 'Please select the records you want to download',
  'user.action.download.success': 'Data download successful',
  'user.action.download.fail': 'Data download failed',
  'user.action.export': 'Export',
  'user.action.export.tip': 'Please select the records you want to export',
  'user.action.export.success': 'Export successful',
  'user.action.export.fail': 'Export failed',
  'user.share.status': 'Share Status',
  'user.share.status.enable': 'Enable',
  'user.share.status.disable': 'Disable',
  'user.share.status.disable.tip': 'The driver has disabled your data viewing permission; they need to enable it within the app for you to view it.',
  'user.action.delete.tip': 'Confirm deletion?',
  'user.action.delete.yes': 'Yes',
  'user.action.delete.no': 'No',
  'user.password.change.password': 'Reset Password',
  'user.password.change.password.tip': 'Please enter your password',
  'user.password.change.passwordReview': 'Confirm Password',
  'user.password.change.password.not.match': 'Passwords do not match',
  'user.action.ok': 'Confirm',
  'user.action.cancel': 'Cancel',
  'user.password.change.ok': 'Save',
  'user.password.change.cancel': 'Cancel',
  'user.password.change.password.current': 'Current Password',
  'user.password.change.password.new': 'New Password',
  'user.password.change.password.new.tip': 'Please enter new password',
  'user.password.change.password.current.tip': 'Please enter current password',
  'user.password.change.passwordReview.new': 'Confirm New Password',
  'user.password.change.passwordReview.new.tip': 'Please re-enter the same new password',
  'user.password.change.password.format.error': 'Password must be a mix of letters and numbers, length 8-20',
  'user.password.level.low': 'Low',
  'user.password.level.medium': 'Medium',
  'user.password.level.high': 'High',
  'user.password.level.error': 'Password strength is insufficient',
  'user.password.level.tip': 'Please enter at least 6 characters. Do not use easily guessable passwords.',
  'user.account.login': 'Login with existing account',
  'user.password.format.tip': 'At least 6 characters, case sensitive',
  'user.email.captcha.send': 'Verification code sending...',
  'user.welcome': 'Welcome',
  'user.welcome.back': 'Welcome back',
  'user.agreement.read': 'Please read and agree to the User Privacy Policy',
  'user.profile': 'User Info',
  'user.car.area': 'Region',
  'user.car.type': 'Classification Number',
  'user.car.plateName': 'Plate Name',
  'user.car.number': 'Serial Number',
  'user.car.color': 'Color',
  'user.car.color.GreenWhite': 'White Plate Green Letters',
  'user.car.color.WhiteGreen': 'Green Plate White Letters',
  'user.car.color.BlackYellow': 'Yellow Plate Black Letters',
  'user.car.color.YellowBlack': 'Black Plate Yellow Letters',
  'user.car.color.GreenBlue': 'Blue Plate Green Letters',
  'user.car.color.RedWhite': 'White Plate Red Letters',
  'user.membership.level': 'Membership Level',
  'user.membership.upgrade': 'Upgrade Membership',
  'user.membership.function': 'Function',
  'user.membership.function.location': 'Display Testing Location',
  'user.membership.function.video': 'Video recording',
  'user.membership.function.excel': 'Excel Export',
  'user.membership.function.email': 'Email Alerts',
  'user.membership.function.data.sync': 'data sync',
  'user.membership.pay': 'Pay',
  'user.membership.days': 'Days',
  'user.membership.function.data.limit': 'Data Viewing Permissions',
  'user.membership.expire.time': 'Membership Expiration Time',
  'user.share.code.checking': 'Verifying Authorization Code',
  'user.share.code.params.err': 'Parameter Error',
  'user.share.bind.tip': 'Bind to get data authorization from {name}.',
  'user.share.bind': 'Confirm',
  'user.test.value.limited': 'Upgrade to Pro or Pro+ membership to view test data',
  'user.webhook.function.tip': 'This feature is only intended for users who need to receive and save their own data and have development capabilities. If you are an ordinary user, please ignore this page.',
  'user.webhook.function.tip1': "If you wish to receive the user's test data, two conditions must be met: 1. The target driver must be on your driver list; 2. This feature is a paid service, ensure that you do not have any outstanding payments.",
  'user.webhook.function.tip2': 'When an associated driver performs an alcohol test, the test data will be pushed to the configured URL via HTTPS POST request.',
  'user.webhook.function.tip3': 'The format of the data pushed is in the following application/json format.',
  'user.webhook.remaining.amount': 'Current Remaining Amount:',
  'user.webhook.remaining.amount.tip': 'This service is a paid service, priced at 20 JPY per data entry. To avoid push failures due to insufficient funds, please ensure that your account has sufficient remaining amount.',
  'user.webhook.charge': 'Recharge',
  'user.webhook.config.empty': 'There is currently no Webhook URL configured',
  'user.webhook.config': 'Start Configuration',
  'user.webhook.config.update': 'Update Configuration',
  'user.webhook.config.mock': 'Mock Test',
  'user.webhook.config.info': 'Webhook Configuration Information',
  'user.webhook.config.url': 'HTTPS URL',
  'user.webhook.config.tip': 'Example: https://example.com/webhook. Must be an https address.',
  'user.webhook.config.tip2': 'The Webhook URL must start with https://',
  'user.webhook.config.tip3': 'The Webhook URL must be verified before saving. Please refer to the',
  'user.webhook.function.tip6': 'After receiving the data, the developer server needs to return the message_id in the JSON to indicate that the server has correctly received and processed the data. If the developer server does not respond correctly, the ALC Manage server will attempt to resend the data three times. If all attempts fail, the data will be marked as a failed send. If the developer wants to retrieve the failed send record, they can go to the push record tab, find the failed send record, and click the resend button to resend the data.',
  'user.webhook.config.check.doc': 'Webhook URL Verification Documentation',
  'user.webhook.config.tip4': 'Write a simple POST interface and respond correctly.',
  'user.webhook.config.check': 'Verify',
  'user.webhook.config.check.success': 'Verification Passed',
  'user.webhook.config.check.fail': 'Interface verification failed, please check if the interface matches the documentation.',
  'user.webhook.config.unit': 'Data Unit',
  'user.webhook.config.unit.tip': 'Please select the data unit according to regional regulations',
  'user.action.submit': 'Submit',
  'user.action.submit.url.error': 'Unverified HTTPS URL',
  'user.webhook.config.key': 'Security Key',
  'user.webhook.config.key.show': 'Show',
  'user.webhook.config.key.hide': 'Hide',
  'user.webhook.config.key.gen': 'Regenerate',
  'user.webhook.config.enable': 'Enable',
  'user.webhook.config.disable': 'Disable',
  'user.webhook.config.tip5': 'Pushing is currently disabled. Ensure that you have completed the interface development as per the right-side documentation before enabling.',
  'user.webhook.config.mock.sended': 'Mock Data Sent',
  'user.webhook.interface.json.reark': 'Description of fields in the JSON',
  'api.field.key': 'Field',
  'api.field.type': 'Field Value Type',
  'api.field.desc': 'Field Description',
  'user.amount.buy.num.tip': 'Please select the recharge amount',
  'user.amount.charge.custom': 'Custom Amount',
  'user.amount.buy.pay': 'You need to pay:',
  'user.amount.buy.submit': 'Pay with Paypal',
  'user.webhook.tab.config': 'Push Configuration',
  'user.webhook.tab.record': 'Push Record',
  'user.test.location.not.pro': 'To view detailed GPS location, please upgrade your membership to PRO+.',
  'user.password.tip': 'Please enter your login password',
  'user.pay.none': 'No payment required',
  'user.pay.now': 'Pay',
  'sys.error': 'Error',
  'sys.user.info.error': 'User information request failed or login is invalid.',
  'user.test.location': 'Location',
  'user.test.video': 'Video',
  'user.service.support': 'Customer Service Support',
  'user.service.doc': 'Document',
  'user.alc.limited.user': ' Pro member(s) have expired, and the data is not in the Excel spreadsheet.',
  'user.alc.xlsx.download.tip': 'Click to download the daily test data.',
  'user.email.alert': 'Mail Push',
  'user.record.data.syncing': 'During data synchronization, download progress:',
  'user.record.data.sync.finish': 'Data synchronization completed.',
  'user.bind.delete.multiple': 'Batch Delete',
  'user.membership.upgrade.multiple': 'Batch Upgrade',
  'user.membership.expire.alert': '{0} drivers\' PRO membership has expired or is about to expire.',
  'user.membership.limit.none': 'unlimited',
  'user.membership.driver.selected': '{num} drivers have been selected,',
  'user.membership.show.details': 'click to show details.',
  'user.membership.upgradeDetail': 'Member Upgrade Details',
  'user.membership.target.user': 'Name',
  'user.membership.target.level': 'Upgraded membership level',
  'user.membership.target.expireDate': 'Membership expiration date',
  'user.membership.level.pro': 'Pro member',
  'user.membership.level.plus': 'Pro+ member',
  'user.membership.pay.success': 'Pay success',
   'user.action.delete.title': 'Warning',
  'user.action.delete.content': 'Are you sure to delete the selected driver?',
  'user.attendance.work.dur': 'Work',
  'user.attendance.rest.dur': 'Rest',
  'user.checkin.stat': 'Attendance statistics',
  'user.checkin.record': 'Attendance Record',
  'user.attendance.work': 'work',
  'user.attendance.rest': 'rest',
  'user.checkin.dur': 'Attendance period',
  'user.checkin.date': 'Date',
  'user.checkin.time': 'Time',
  'user.checkin.action': 'Action',
  'user.checkin.location': 'Location',
  'user.checkin.action.clockin': 'Clock in',
  'user.checkin.action.clockout': 'Clock out',
  'user.checkin.action.breakstart': 'Start break',
  'user.checkin.action.breakend': 'End break',
  'user.bind.num': 'Bind {count} people in total',
  'user.alc.test.num': '{count} people have undergone alcohol testing',
  'user.checkin.driver': 'Driver',
  'user.checkin.stat.date': 'Statistical date',
  'user.checkin.stat.month': 'Statistical month',
  'user.checkin.work.dur.real': 'Actual working hours',
  'user.checkin.work.dur': 'Working hours',
  'user.checkin.rest.dur': 'Rest hours',
  'user.checkin.stat.single.day': 'Daily mode',
  'user.checkin.stat.more.day': 'Monthly mode',
  'user.checkin.stat.expire.tip': '{num} people are not included in the statistics due to expired membership. You can open PRO membership for expired members or view their records for free and manually count them.',
'user.drive.log.start': 'Start driving',
  'user.drive.log.end': 'End driving',
  'user.drive.log.time': 'Time',
  'user.drive.log.address': 'Address',
  'user.drive.log.start_address': 'Starting Point Address',
  'user.drive.log.end_address': 'Destination Address'
}
