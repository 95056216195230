
export default {
  'main.title': '酒精测试管理',
  'menu.webhook': '数据推送',
  'menu.drivelog': '驾驶日志',
   'menu.attendance': '打卡记录',
  'home.home': '首页',
  'user.account': '账号',
  'user.email': '邮箱',
  'user.input.keyboard.error': '请将键盘输入法设置为半角模式',
  'user.email.tip': '请输入邮箱地址',
  'user.password': '密码',
  'user.reg': '注册',
  'user.reg.success': '注册成功',
  'user.reg.fail': '注册失败',
  'user.reg.success.description': '即将返回登录页面',
  'user.regnow': '立即注册',
  'user.login': '登录',
  'user.name.change.title': '修改姓名',
  'user.signout': '注销登录',
  'user.captcha': '验证码',
  'user.signInGoogle': '使用Google登录',
  'user.emailCaptcha': '邮箱验证码',
  'user.emailCaptcha.tip': '请输入邮箱验证码',
  'user.getCaptcha': '获取验证码',
  'user.readAndAgree': '我已阅读并同意',
  'user.UserServiceAgreement': '用户服务协议',
  'user.action.delete': '删除',
  'user.action.more': '更多操作',
  'menu.record': '测试记录',
  'menu.driver': '司机管理',
  'menu.title': '酒精测试管理',
  'menu.daily.data': '数据日报',
  'user.accountSetting': '账户设置',
  'user.tip': '提示',
  'user.signOutTip': '真的要注销登录吗 ?',
  'table.emptyText': '暂无数据',
  'user.bind.num': '共绑定{count}人',
  'user.alc.test.num': '{count}人进行了酒精测试',
  'user.action': '操作',
  'user.name': '姓名',
  'user.name.tip': '请输入姓名',
  'user.bindTime': '绑定时间',
  'user.action.search': '搜索',
  'user.action.bind': '绑定司机',
  'user.action.qrWinOpenTip': '请勿在司机扫码授权完成前关闭该窗口。',
  'user.action.scanBind': '通过扫描二维码绑定司机',
  'user.action.emailBind': '通过输入管理员邮箱绑定司机',
  'user.visit.tip': '请当面邀请司机扫描该二维码。',
  'user.action.bind.success': '司机绑定成功',
  'user.test.id': '序列号',
  'user.test.value': '检测值(mg/L)',
  'user.test.date': '检测日期',
  'user.test.time': '检测时间',
  'user.id': '司机ID',
  'user.test.appraise': '评估',
  'user.test.user': '测试人',
  'user.test.image': '照片',
  'user.test.appraise.yes': 'Yes',
  'user.test.appraise.no': 'No',
  'user.car.license_plate_number': '车牌号',
  'user.action.download': '下载',
  'user.action.download.tip': '请选择需要下载的记录',
  'user.action.download.success': '下载数据成功',
  'user.action.download.fail': '下载数据失败',
  'user.action.export': '导出',
  'user.action.export.tip': '请选择要导出的记录',
  'user.action.export.success': '导出成功',
  'user.action.export.fail': '导出失败',
  'user.share.status': '共享状态',
  'user.share.status.enable': '启用',
  'user.share.status.disable': '禁用',
  'user.share.status.disable.tip': '司机禁用了您的数据查看权限，如需开启需要司机进入APP开启',
  'user.action.delete.tip': '确认删除吗？',
  'user.action.delete.yes': '是',
  'user.action.delete.no': '否',
  'user.password.change.password': '重置密码',
  'user.password.change.password.tip': '请输入密码',
  'user.password.change.passwordReview': '确认密码',
  'user.password.change.password.not.match': '两次输入密码不一致',
  'user.action.ok': '确认',
  'user.action.cancel': '取消',
  'user.password.change.ok': '保存',
  'user.password.change.cancel': '取消',
  'user.password.change.password.current': '当前密码',
  'user.password.change.password.new': '新密码',
  'user.password.change.password.new.tip': '请输入新密码',
  'user.password.change.password.current.tip': '请输入当前密码',
  'user.password.change.passwordReview.new': '确认新密码',
  'user.password.change.passwordReview.new.tip': '请再次输入相同的新密码',
  'user.password.change.password.format.error': '密码必须为字母数字混合，长度为8-20',
  'user.password.level.low': '低',
  'user.password.level.medium': '中',
  'user.password.level.high': '高',
  'user.password.level.error': '密码强度不够',
  'user.password.level.tip': ' 请至少输入 6 个字符。请不要使用容易被猜到的密码。',
  'user.account.login': '使用已有账户登录',
  'user.password.format.tip': '至少6位密码，区分大小写',
  'user.email.captcha.send': '验证码发送中..',
  'user.welcome': '欢迎',
  'user.welcome.back': '欢迎回来',
  'user.agreement.read': '请先阅读并同意用户隐私协议',
  'user.profile': '用户信息',
  'user.car.area': '地域',
  'user.car.type': '分类番号',
  'user.car.plateName': '平板名',
  'user.car.number': '序列编号',
  'user.car.color': '颜色',
  'user.car.color.GreenWhite': '白牌緑字',
  'user.car.color.WhiteGreen': '緑牌白字',
  'user.car.color.BlackYellow': '黄牌黑字',
  'user.car.color.YellowBlack': '黑牌黄字',
  'user.car.color.GreenBlue': '蓝牌绿字',
  'user.car.color.RedWhite': '白牌红字',
  'user.membership.level': '会员等级',
  'user.membership.upgrade': '升级会员',
  'user.membership.function': '功能',
  'user.membership.function.location': '显示测试地点',
  'user.membership.function.video': '录像10秒',
  'user.membership.function.excel': 'EXCEL导出',
  'user.membership.function.email': '邮件通知',
  'user.membership.function.data.sync': '数据恢复',
  'user.membership.days': '天',
  'user.membership.pay': '支付',
  'user.membership.function.data.limit': '数据查看权限',
  'user.membership.expire.time': '会员过期时间',
  'user.share.code.checking': '正在校验授权码',
  'user.share.code.params.err': '参数错误',
  'user.share.bind.tip': '绑定获取{name}的数据授权。',
  'user.share.bind': '确定',
  'user.test.value.limited': '升级Pro或者Pro+会员才能查看测试数据',
  'user.webhook.function.tip': '此功能仅针对需要自行接收保存数据且有开发能力的用户。若您为普通用户，请忽略该页面。',
  'user.webhook.function.tip1': '若您想收到用户的测试数据，需要满足2个条件：1.目标司机用户在您的司机列表中；2.此功能为付费服务，确保您没有欠费。',
  'user.webhook.function.tip2': '当有关联的司机进行酒精测试后，测试数据将通过https POST请求推送到您设定URL地址。',
  'user.webhook.function.tip3': 'プッシュされるデータフォーマットは、次のようなapplication/jsonフォーマットです。',
  'user.webhook.remaining.amount': '当前剩余金额：',
  'user.webhook.remaining.amount.tip': '当前服务为付费项目，价格为每条数据20JPY。为避免数据因金额不足而推送失败，请保证您的账户有足够的剩余金额。',
  'user.webhook.charge': '充值',
  'user.webhook.config.empty': '当前还没有配置Webhook地址',
  'user.webhook.config': '开始配置',
  'user.webhook.config.update': '修改配置',
  'user.webhook.config.mock': '模拟测试',
  'user.webhook.config,info': 'Webhook配置信息',
  'user.webhook.config.url': 'HTTPS URL',
  'user.webhook.config.tip': '例：https://example.com/webhook。必须为https地址。',
  'user.webhook.config.tip2': 'Webhook地址必须以https://开头',
  'user.webhook.config.tip3': 'Webhook地址必须经过验证后才能保存。请参考',
  'user.webhook.function.tip6': '开发者服务器在收到数据后，需要返回JSON中的message_id，表明该服务器已正确接收并处理该数据。若开发者服务器未正确回复，ALC Manage服务器将尝试重新发送3次。若3次都失败，该数据将标记为发送失败。若开发者想找回该发送失败的记录，可进入推送记录标签页，找到发送失败的记录，点击重发按钮再次发送数据。',
  'user.webhook.config.check.doc': 'Webhook URL验证文档',
  'user.webhook.config.tip4': '编写一个简单的POST接口，并进行正确的响应。',
  'user.webhook.config.check': '验证',
  'user.webhook.config.check.success': '验证通过',
  'user.webhook.config.check.fail': '接口验证未通过,请再次核对接口与文档是否一致。',
  'user.webhook.config.unit': '数据单位',
  'user.webhook.config.unit.tip': '请根据区域规定选择推送数据的数据单位',
  'user.action.submit': '提交',
  'user.action.submit.url.error': '未验证HTTPS URL',
  'user.webhook.config.key': '安全秘钥',
  'user.webhook.config.key.show': '显示',
  'user.webhook.config.key.hide': '隐藏',
  'user.webhook.config.key.gen': '重新生成',
  'user.webhook.config.enable': '启用',
  'user.webhook.config.disable': '禁用',
  'user.webhook.config.tip5': '当前未启用推送，请确保您已经按照右侧文档完成接口开发再启用。',
  'user.webhook.config.mock.sended': '已发送模拟数据',
  'user.webhook.interface.json.reark': '关于json中各字段的说明',
  'api.field.key': '字段',
  'api.field.type': '字段值类型',
  'api.field.desc': '字段说明',
  'user.amount.buy.num.tip': '请选择充值金额',
  'user.amount.charge.custom': '自定义金额',
  'user.amount.buy.pay': '您需要支付：',
  'user.amount.buy.submit': '使用Paypal支付',
  'user.webhook.tab.config': '推送配置',
  'user.webhook.tab.record': '推送记录',
  'user.test.location.not.pro': '要查看详细的GPS位置，请将会员升级为PRO+会员身份。',
  'user.password.tip': '请输入您的登录密码',
  'user.pay.none': '无需支付',
  'user.pay.now': '立即支付',
  'sys.error': '错误',
  'sys.user.info.error': '用户信息请求失败或登录无效。',
  'user.test.location': '测试地点',
  'user.test.video': '录像',
  'user.service.support': '客服支持',
  'user.service.doc': '文档',
  'user.alc.limited.user': '人Pro会员过期，数据不在Excel表格中。',
  'user.alc.xlsx.download.tip': '点击下载当日测试数据',
  'user.email.alert': '邮件推送',
  'user.record.data.syncing': '数据同步中，下载进度：',
  'user.record.data.sync.finish': '数据同步完成',
  'user.bind.delete.multiple': '批量删除',
  'user.membership.upgrade.multiple': '批量升级',
  'user.membership.expire.alert': '{num}名司机的PRO会员过期或即将过期',
  'user.membership.limit.none': '无限制',
  'user.membership.driver.selected': '已选择{num}位司机，',
  'user.membership.show.details': '点击查看详情。',
  'user.membership.upgradeDetail': '会员升级详细信息',
  'user.membership.target.user': '姓名',
  'user.membership.target.level': '升级后会员等级',
  'user.membership.target.expireDate': '升级后会员到期日',
  'user.membership.level.pro': 'Pro会员',
  'user.membership.level.plus': 'Pro+会员',
  'user.membership.pay.success': '支付成功',
  'user.action.delete.title': '提示',
  'user.action.delete.content': '确认删除所选司机吗？',
  'user.attendance.work': '工作',
  'user.attendance.rest': '休息',
  'user.attendance.work.dur': '工作',
  'user.attendance.rest.dur': '休息',
  'user.checkin.stat': '考勤统计',
  'user.checkin.record': '考勤记录',
  'user.checkin.dur': '出勤时间段',
  'user.checkin.date': '打卡日期',
  'user.checkin.time': '打卡时间',
  'user.checkin.action': '打卡操作',
  'user.checkin.location': '打卡位置',
  'user.checkin.action.clockin': '出勤',
  'user.checkin.action.clockout': '退勤',
  'user.checkin.action.breakstart': '开始休息',
  'user.checkin.action.breakend': '结束休息',
  'user.checkin.driver': '司机',
  'user.checkin.stat.date': '统计日期',
  'user.checkin.stat.month': '统计月份',
  'user.checkin.work.dur.real': '实际工作时长',
  'user.checkin.work.dur': '工作时长',
  'user.checkin.rest.dur': '休息时长',
  'user.checkin.stat.single.day': '多人单日统计',
  'user.checkin.stat.more.day': '单人单月统计',
  'user.checkin.stat.expire.tip': '{num}人会员过期，不在统计范围中。您可为会员过期人员开通PRO会员或者免费查看其记录并人工统计。',
  'user.drive.log.start': '开始驾驶',
'user.drive.log.end': '结束驾驶',
  'user.drive.log.time': '时间',
'user.drive.log.address': '地点',
  'user.drive.log.start_address': '出发点',
  'user.drive.log.end_address': '终点'
}
