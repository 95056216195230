export default {
  'main.title': '飲酒運転検査',
  'menu.webhook': 'データプッシュ',
  'menu.drivelog': '運転日誌',
   'menu.attendance': '勤怠記録',
  'home.home': 'ホーム',
  'user.account': 'アカウント',
  'user.email': 'メールアドレス',
  'user.input.keyboard.error': 'キーボード入力方式を半角モードに設定してください',
  'user.email.tip': 'メールアドレスを入力してください',
  'user.password': 'パスワード',
  'user.reg': '登録',
  'user.reg.success': '登録が完了しました',
  'user.reg.fail': '登録に失敗しました',
  'user.reg.success.description': 'ログインページに戻ります',
  'user.regnow': '今すぐ登録',
  'user.login': 'ログイン',
  'user.signout': 'ログアウト',
  'user.captcha': 'キャプチャ',
  'user.captcha.tip': '認証コードを入力してください',
  'user.signInGoogle': 'Googleでログイン',
  'user.emailCaptcha': 'メールアドレスのキャプチャ',
  'user.emailCaptcha.tip': 'メールアドレスのキャプチャを入力してください',
  'user.getCaptcha': 'キャプチャを取得',
  'user.readAndAgree': '私は以下の条項に同意します',
  'user.UserServiceAgreement': 'ユーザーサービス契約',
  'user.action.delete': '削除',
  'user.action.more': '他の操作',
  'menu.record': 'テスト記録',
  'menu.driver': '運転手管理',
  'menu.title': '飲酒運転検査',
  'menu.daily.data': 'データ日報',
  'user.accountSetting': 'ログインパスワードの変更',
  'user.name.change.title': 'ニックネームの変更',
  'user.name.new': 'ニックネーム',
  'user.name.new.tip': '新しいニックネームを入力してください',
  'user.tip': 'に注意',
  'user.signOutTip': '本当にログアウトしますか?',
  'table.emptyText': 'データがありません',
  'user.action': '操作',
  'user.name': '氏名',
  'user.name.tip': '名前を入力してください',
  'user.bindTime': '運転手登録日時',
  'user.action.search': '検索',
  'user.action.bind': 'マネージャーQRコード',
  'user.action.qrWinOpenTip': '運転手がQRコードをスキャンするまでこのウィンドウを閉じないでください。',
  'user.action.scanBind': 'マネージャーQRコード',
  'user.action.emailBind': '管理者のメールアドレスを入力して運転手とバインド',
  'user.visit.tip': '運転手の方々に、このQRコードをアルコールセンサーアプリでスキャンし、管理者がアルコール検査結果にアクセスできるようにしてください。',
  'user.action.bind.success': '運転手とのバインドが成功しました',
  'user.test.value': '検査値（mg/L）',
  'user.test.date': '検査日',
  'user.test.time': '検査時刻',
  'user.id': '運転手ID',
  'user.test.id': 'シリアル',
  'user.test.appraise': '結果',
  'user.test.user': '被験者名',
  'user.test.image': '画像',
  'user.test.appraise.yes': '未検出',
  'user.test.appraise.no': '運転中止',
  'user.test.appraise.warn': '運転控え',
  'user.car.license_plate_number': '車両番号',
  'user.action.download': 'ダウンロード',
  'user.action.download.tip': 'ダウンロードするレコードを選択してください',
  'user.action.download.success': 'エクスポートが成功しました',
  'user.action.download.fail': 'エクスポートに失敗しました',
  'user.action.export': 'エクスポート',
  'user.action.export.tip': 'エクスポートする記録を選択してください',
  'user.action.export.success': 'データのダウンロードに成功しました',
  'user.action.export.fail': 'データのダウンロードに失敗しました',
  'user.share.status': '共有状態',
  'user.share.status.enable': '有効',
  'user.share.status.disable': '無効',
  'user.share.status.disable.tip': '運転手があなたのデータ閲覧権限を無効にしました。有効にするには、運転手がアプリを開いて設定を行っていただく必要があります。',
  'user.action.delete.tip': '本当に削除しますか?',
  'user.action.delete.yes': 'はい',
  'user.action.delete.no': 'いいえ',
  'user.password.tip': 'パスワードを入力してください',
  'user.password.change.password': 'パスワードの変更',
  'user.password.change.password.tip': '新しいパスワードを入力してください',
  'user.password.change.passwordReview': 'パスワードの確認',
  'user.password.change.password.not.match': 'パスワードが一致しません',
  'user.action.ok': '確認',
  'user.action.cancel': 'キャンセル',
  'user.password.change.ok': '保存',
  'user.password.change.cancel': 'キャンセル',
  'user.password.change.password.current': '現在のパスワード',
  'user.password.change.password.new': '新しいパスワード',
  'user.password.change.password.new.tip': '新しいパスワードを入力してください',
  'user.password.change.passwordReview.new': '新しいパスワードの確認',
  'user.password.change.passwordReview.new.tip': '同じ新しいパスワードをもう一度入力してください',
  'user.password.change.password.current.tip': '現在のログインパスワードを入力してください',
  'user.password.change.password.format.error': 'パスワードは英数字を混ぜて8〜20文字で入力してください',
  'user.password.level.low': '低い',
  'user.password.level.medium': '中',
  'user.password.level.high': '高い',
  'user.password.level.error': 'パスワードの強度が足りません',
  'user.password.level.tip': '少なくとも6文字以上を入力してください。予測しやすいパスワードを使用しないでください。',
  'user.account.login': '既存のアカウントでログイン',
  'user.password.format.tip': '最低6文字のパスワードを設定してください。大文字と小文字の区別があります。',
  'user.email.captcha.send': 'キャプチャの送信中…',
  'user.welcome': 'ようこそ',
  'user.welcome.back': 'お帰りなさい',
  'user.agreement.read': 'ユーザープライバシー契約を読んで同意してください',
  'user.profile': '運転手情報',
  'user.car.area': '地域名',
  'user.car.type': '分类番号',
  'user.car.plateName': 'ひらがな',
  'user.car.number': '一連指定番号',
  'user.car.color': '色',
  'user.car.color.GreenWhite': '白地に緑文字',
  'user.car.color.WhiteGreen': '緑牌に白文字',
  'user.car.color.BlackYellow': '黄牌に黑文字',
  'user.car.color.YellowBlack': '黑牌に黄文字',
  'user.car.color.GreenBlue': '蓝牌に绿文字',
  'user.car.color.RedWhite': '白牌に红文字',
  'user.membership.level': '会員ランク',
  'user.membership.upgrade': '会員のアップグレード',
  'user.membership.function': 'ファンクション',
  'user.membership.function.location': '検査場所表示',
  'user.membership.function.video': 'ビデオ記録 10秒',
  'user.membership.function.email': 'メールアラート通知',
  'user.membership.function.excel': 'EXCEL配信',
  'user.membership.function.bindNum': '関連人数の上限',
  'user.membership.function.data.sync': 'データ同期',
  'user.membership.days': '日間',
  'user.membership.pay': '支払い',
  'user.membership.expire.time': '会員の有効期限',
  'user.membership.function.data.limit': 'データ表示権限',
  'user.test.location': '検査場所',
  'user.test.video': 'ビデオ',
  'user.test.video.play': 'クリックしてビデオを再生',
  'user.test.data.new.tip': '新しいデータが検出されました。検索ボタンをクリックしてリストを更新してご覧ください。',
  'user.excel.appraise.yes': '基準値内',
  'user.excel.appraise.no': '基準値オーバー',
  'user.excel.exporting': 'データをエクスポートしています...',
  'user.bind.num': '合計{count}人のバインド',
  'user.alc.test.num': '{count}人がアルコールテストを行った',
  'user.alc.xlsx.download.tip': 'クリックして当日のデータをダウンロード',
  'user.alc.xlsx.auto.send': '自動送信設定',
  'user.share.code.checking': '認証コードを検証しています',
  'user.share.code.params.err': 'パラメータエラー',
  'user.share.bind.tip': '{name}のリンクを取得します。',
  'user.share.bind': '許可',
  'user.test.value.limited': 'ProまたはPro+会員をアップグレードしてテストデータを表示する',
  'user.webhook.function.tip': 'この機能は、保存されたデータを自分で受信する必要があり、開発能力のあるユーザーのみを対象としています。一般ユーザーの場合は、ページを無視してください。',
  'user.webhook.function.tip1': 'ユーザーからテストデータを受け取るには、2つの条件を満たす必要があります。1.ターゲットドライバーユーザーはドライバーリストにあります。2.この機能は有料サービスで、未払いがないことを確認します。',
  'user.webhook.function.tip2': '関連するエンドユーザーがアルコールテストを行った後、テストデータはhttps POST要求によりURLアドレスの設定に送信されます。',
  'user.webhook.function.tip3': 'プッシュされるデータフォーマットはapplication/jsonフォーマットです。',
  'user.webhook.remaining.amount': '現在の残存金額：',
  'user.webhook.remaining.amount.tip': '現在のサービスは有料アイテムで、価格は1データあたり20 JPYです。金額不足によるデータのプッシュ失敗を回避するために、アカウントに十分な残高があることを保証してください。',
  'user.webhook.charge': 'チャージ',
  'user.webhook.config.empty': '現在Webhookアドレスは設定されていません',
  'user.webhook.config': '構成の開始',
  'user.webhook.config.update': '構成の変更',
  'user.webhook.config.mock': 'シミュレーションテスト',
  'user.webhook.config,info': 'Webhook構成情報',
  'user.webhook.config.url': 'HTTPS URL',
  'user.webhook.config.tip': '例：https://example.com/webhook。httpsで始めなければなりません。',
  'user.webhook.config.tip2': 'Webhookアドレスはhttps://で始まる必要があります',
  'user.webhook.config.tip3': 'Webhookアドレスを保存するには、検証後でなければなりません。参照してください',
  'user.webhook.function.tip6': '開発者サーバはデータを受信すると、JSONのmessage _ idに戻り、サーバがデータを正しく受信して処理していることを示す必要があります。開発者サーバが正しく返信していない場合、ALC Manageサーバは3回再送信を試みます。3回とも失敗すると、データは送信失敗としてマークされます。開発者がその送信に失敗したレコードを取り戻したい場合は、プッシュログタブページにアクセスし、送信に失敗したレコードを見つけ、再送ボタンをクリックしてデータを再送信することができます。',
  'user.webhook.config.check.doc': 'Webhook URL検証ドキュメント',
  'user.webhook.config.tip4': '簡単なPOSTインタフェースを作成し、適切な応答を行います。',
  'user.webhook.config.check': '検証',
  'user.webhook.config.check.success': '検証に合格しました',
  'user.webhook.config.check.fail': 'インタフェースの検証に失敗しました。インタフェースとドキュメントが一致しているかどうかをもう一度確認してください。',
  'user.webhook.config.unit.tip': '領域規定に基づいてデータをプッシュするデータ単位を選択してください',
  'user.webhook.config.unit': 'データ単位',
  'user.webhook.config.key': 'セキュリティキー',
  'user.webhook.config.key.show': '表示',
  'user.webhook.config.key.hide': '非表示',
  'user.webhook.config.key.gen': '再生成',
  'user.action.submit': '送信',
  'user.action.submit.url.error': 'HTTPS URLが検証されていません',
  'user.webhook.config.enable': '有効化',
  'user.webhook.config.disable': '無効化',
  'user.webhook.config.tip5': '現在プッシュは有効になっていません。右側のドキュメントに従ってインタフェースの開発を完了して有効になっていることを確認してください。',
  'user.webhook.config.mock.sended': '送信されたシミュレーションデータ',
  'user.webhook.interface.json.reark': 'jsonのフィールドの説明',
  'api.field.key': 'フィールド',
  'api.field.type': 'フィールド値タイプ',
  'api.field.desc': 'フィールドの説明',
  'user.amount.buy.num.tip': 'チャージ金額を選択してください',
  'user.amount.charge.custom': 'カスタム金額',
  'user.amount.buy.pay': '支払いが必要です：',
  'user.amount.buy.submit': 'Paypalで支払う',
  'user.webhook.tab.config': 'プッシュ構成',
  'user.webhook.tab.record': 'プッシュレコード',
  'user.test.location.not.pro': '詳細なGPS位置情報を確認するには、メンバーシップをPRO+会員にアップグレードしてください。',
  'user.pay.none': '支払い不要',
  'user.pay.now': '即時支払い',
  'sys.error': 'エラー',
  'sys.user.info.error': 'ユーザー情報要求が失敗したか、ログインが無効です。',
  'user.service.support': 'カスタマーサポート',
  'user.service.doc': 'ドキュメント',
  'user.alc.limited.user': '人のPro会員は期限切れで、データはExcelテーブルにありません。',
  'user.email.alert': 'メール送信',
  'user.record.data.syncing': 'データの同期中、進行状況：',
  'user.record.data.sync.finish': 'データの同期が完了しました。',
  'user.bind.delete.multiple': '選択解除',
  'user.membership.upgrade.multiple': '一括アップグレード',
  'user.membership.expire.alert': '運転手{num}人のPRO会員は期限切れか期限切れになる',
  'user.membership.limit.none': '制限なし',
  'user.membership.driver.selected': '{num}ビットドライバが選択されました，',
  'user.membership.show.details': 'クリックして詳細を表示。',
  'user.membership.upgradeDetail': '会員のアップグレード詳細',
  'user.membership.target.user': '氏名',
  'user.membership.target.level': 'アップグレード後の会員ランク',
  'user.membership.target.expireDate': 'アップグレード後の会員期限',
  'user.membership.level.pro': 'Pro会員',
  'user.membership.level.plus': 'Pro+会員',
  'user.membership.pay.success': '支払いに成功しました',
  'user.action.delete.title': 'ヒント',
  'user.action.delete.content': '選択した運転手の削除を確認しますか？',
  'user.attendance.work.dur': '作業',
  'user.attendance.rest.dur': '休憩',
  'user.attendance.work': '作業',
  'user.attendance.rest': '休憩',
  'user.checkin.dur': '出勤期間',
  'user.checkin.stat': '勤怠統計',
  'user.checkin.record': '勤怠記録',
  'user.checkin.date': '打刻日付',
  'user.checkin.time': '打刻時間',
  'user.checkin.action': '操作',
  'user.checkin.location': '打刻位置',
  'user.checkin.action.clockin': '出勤',
  'user.checkin.action.clockout': '退勤',
  'user.checkin.action.breakstart': '休憩中',
  'user.checkin.action.breakend': '休憩終了',
  'user.checkin.driver': '運転手',
  'user.checkin.stat.date': '統計日付',
  'user.checkin.stat.month': '統計月',
  'user.checkin.work.dur.real': '実働期间',
  'user.checkin.work.dur': '作業時間',
  'user.checkin.rest.dur': '休憩時間',
  'user.checkin.stat.single.day': 'マルチデー統計',
  'user.checkin.stat.more.day': 'シングル月間統計',
  'user.checkin.stat.expire.tip': '{num}人は会員の期限切れのため、統計の範囲外です。会員の有効期限が切れた人のためにPRO会員を開設するか、無料でその記録を見て手動で統計することができます。',
  'user.drive.log.start': '運転開始',
  'user.drive.log.end': '運転終了',
  'user.drive.log.time': '時間',
  'user.drive.log.start_address': '出発地点',
  'user.drive.log.end_address': '終了地点'
}
