<script>
import STable from '@components/Table'
import { loadPermissionList, loadUserDriveLogList } from '@/api/data'
import moment from 'moment'
export default {
  name: 'DriveLog',
  components: {
    STable
  },
  data () {
    return {
      drivers: [],
      columns: [
        {
          title: this.$t('user.name'),
          dataIndex: 'name',
          align: 'center',
          width: 160
        },
        {
          title: this.$t('user.account'),
          dataIndex: 'account',
          align: 'center',
          width: 160
        },
        {
          title: this.$t('user.drive.log.start'),
          children: [
            {
              title: this.$t('user.drive.log.time'),
              width: 160,
              dataIndex: 'start_time_t',
              align: 'center'
            },
            {
              title: this.$t('user.drive.log.start_address'),
              dataIndex: 'start_address',
              scopedSlots: { customRender: 'start_address' },
              align: 'center'
            }
          ]
        },
        {
          title: this.$t('user.drive.log.end'),
          children: [
            {
              title: this.$t('user.drive.log.time'),
              dataIndex: 'end_time_t',
              align: 'center'
            },
            {
              title: this.$t('user.drive.log.end_address'),
              dataIndex: 'end_address',
              scopedSlots: { customRender: 'end_address' },
              align: 'center'
            }
          ]
        }
      ],
      height: window.document.body.clientHeight - 250,
      queryObj: {},
      loadData: paramter => {
        return loadUserDriveLogList(Object.assign(this.queryObj, paramter)).then(res => {
          const data = res.result.data
          for (const item of data) {
            item.start_time_t = moment(item.start_time).format('YYYY-MM-DD HH:mm:ss')
            item.start_location = JSON.parse(item.start_location)
            if (item.end_time) {
              item.end_time_t = moment(item.end_time).format('YYYY-MM-DD HH:mm:ss')
              item.end_location = JSON.parse(item.end_location)
            }
          }
          return res.result
        })
      }
    }
  },
  created () {
    loadPermissionList({ pageNo: 1, pageSize: 100 }).then(res => {
      const ds = res.result.data
      this.drivers = ds
      const users = []
      for (const d of ds) {
        users.push(d.user_id)
      }
      this.allDvs = users
      this.queryObj.uid = users
      this.$refs.table.refresh()
    })
  },
  methods: {
    showLocation (location, isPro) {
      if (!isPro) {
        this.$message.error(this.$t('user.test.location.not.pro'))
        return
      }
      window.open('https://www.google.com/maps/place/' + location.loc_gl, '_blank')
    },
    handleChangeDriver (e) {
      if (e) {
        this.queryObj.uid = [e]
      } else {
        this.queryObj.uid = this.allDvs
      }
    },
    search () {
      this.$refs.table.refresh()
    }
  }
}
</script>

<template>
  <div>
    <div class="ant-card" style="padding: 8px;">
      <a-row :gutter="12">
        <a-col :xxl="{span:6}" :lg="{span:6}">
          <div class="form-item">
            <span class="form-item-label">{{ $t('user.name') }}:</span>
            <a-select class="form-content" allowClear @change="handleChangeDriver">
              <a-select-option v-for="driver in drivers" :value="driver.user_id" :key="driver.user_id">{{ driver.name }}</a-select-option>
            </a-select>
          </div>
        </a-col>
        <a-col :lg="{span:6}">
          <div class="form-item">
            <a-button class="form-item-label" type="primary" @click="search">{{ $t('user.action.search') }}</a-button>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="ant-card" style="margin-top: 8px;">
      <s-table
        ref="table"
        :columns="columns"
        :data="loadData"
        bordered
        size="small"
        :scroll="{x:1100,y:height}">
        <div slot="start_address" slot-scope="text, record">
          <div><a @click="showLocation(record.start_location, record.is_pro)">{{ decodeURIComponent(record.start_location.loc_str) }}</a></div>
        </div>
        <div slot="end_address" slot-scope="text, record">
          <div><a @click="showLocation(record.end_location, record.is_pro)" v-if="record.end_location">{{ decodeURIComponent(record.end_location.loc_str) }}</a></div>
        </div>
      </s-table>
    </div>
  </div>
</template>

<style scoped lang='less'>
   .form-item{
     display: flex;
     line-height: 32px;

     .form-item-label{

     }

     .form-content{
       flex:1;
       margin-left: 8px;
     }
   }
</style>
